import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getDecade, formatDuration, intervalToDuration } from "date-fns";

import * as styles from "./Stats.module.scss";


const Stats = (props) => {
  const [currentHost, setCurrentHost] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [allAlbums, setAllAlbums] = useState();
  const [theAlbums, setTheAlbums] = useState();
  const [theTracks, setTheTracks] = useState();
  const [theHosts, setTheHosts] = useState();
  const [theYears, setTheYears] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/albums.json");
      let albums = await response.json();
      // albums = _.filter(albums, {host: "ed"});
      setTheHosts(hostsByCount(albums));
      setTheYears(years(albums));
      setTheAlbums(albums);
      setAllAlbums(albums);
    }
    fetchData();
  }, []);

  useEffect(() => {
    theAlbums && setTheTracks(slicetheTracks(theAlbums));
  }, [theAlbums]);

  const hostsByCount = (albums) => {
    const hosts = _.countBy(albums, "host");
    return hosts;
  };

  // const yearToDate = (year) => (
  //   new Date(Date.parse(year))
  // );

  const years = (albums) => {
    const years = _
      .countBy(albums, "year")
      // .map(a=>a.year)
      // .filter(Number)
      // .sort();
      // return years;
    const decades = _.
      countBy(albums.map(a => getDecade(new Date(a.year, 0, 1))));
    return {
      all: years,
      decades,
      oldest: Object.keys(years)[0],
      newest: Object.keys(years).filter(Number).slice(-1)[0],
    };
  };

  // Used this pattern.
  const slicetheTracks = (albums) => {
    albums = _.filter(albums, a=>a.tracks).map(alb => ({
      ...alb,
      length: alb.tracks.reduce((total, track) => track.duration + total,0),
      count: alb.tracks.length,
    }));
    return {
      byLength: _.sortBy(albums, "length"),
      totalLength: albums.reduce((total, album) => total + album.length,0),
      byCount: _.sortBy(albums, "tracks"),
      totalCount: albums.reduce((total, album) => total + album.count,0),
    };
  };

  const byArtist = (albums) => {
    return albums.reduce((artists, album) => ({
      ...artists,
      [album.artist]: [].concat(
        album.title,
        artists[album.artist]
          ? artists[album.artist]
          : [],
      )
    }), {});
  };

  const selectHost = (host) => {
    setCurrentHost(host);
    setCurrentYear(null);
    if (host) {
      setTheAlbums(_.filter(allAlbums, {host: host}))
      setTheYears(years(_.filter(allAlbums, {host: host})))
    } else {
      setTheAlbums(allAlbums);
      setTheYears(years(allAlbums));
    }
  };

  const selectYear = (year) => {
    setCurrentHost(null);
    setCurrentYear(year);
    if (year) {
      setTheAlbums(_.filter(allAlbums, a => a.played_on.slice(0,4) === `${year}`))
      setTheYears(years(_.filter(allAlbums, a => a.played_on.slice(0,4) === `${year}`)))
    } else {
      setTheAlbums(allAlbums);
      setTheYears(years(allAlbums));
    }
  };

  const secondsToDuration = (seconds) => {
    const start = new Date();
    const end = new Date(start);
    end.setSeconds(seconds);
    return toDuration(start, end);
  };

  const toDuration = (start, end) => (
    formatDuration(intervalToDuration({
      start,
      end,
    }), {
      delimiter: ", ",
    })
  );

  // From and To are numbers (e.g. 1998).
  const buildDates = (from, to) => {
    from = parseInt(from, 10);
    to = parseInt(to, 10);
    return Array.from(Array(to-from+1), (e, i) => i+from);
  };

  /**
  * Provides an array of decades in order of year.
  * @param {number} from - beginning decade.
  * @param {number} to - ending decade.
  * @return {array[number]} e.g. [1990, 2000].
  */
  const buildDecades = (from, to) => {
    from = parseInt(getDecade(new Date(from, 0, 1)));
    to = parseInt(getDecade(new Date(to, 0, 1)));
    let decades = (to-from) / 10 + 1;
    return Array.from(Array(decades), (e, i) => from + i * 10);
  };

  return (
    <div style={{ margin: 30 }}>
      {theAlbums && (<>
        {/* <pre style={{fontFamily:'monospace'}}>{JSON.stringify(_.countBy(theAlbums, "host"), null, 2)}</pre> */}
        {/* <div>{_.uniqBy(theAlbums, "host").length} hosts</div> */}

        <ul style={{ display: "inline"}}>
          <li style={{ display:"inline", margin:8}}><a href={`#`} onClick={() => selectHost("")}>Everyone</a></li>
          {Object.keys(theHosts).map((host) => (
            <li key={host} style={{ display:"inline", margin:8}}>
              <a href={`#${host}`} onClick={() => selectHost(host)}>{host} ({theHosts[host]})</a>
            </li>
          ))}
        </ul>

        <hr />

        {allAlbums && <ol style={{ display: "inline"}}>
          <li style={{ display:"inline", margin:8}}><a href={`#`} onClick={() => selectYear("")}>Every year</a></li>
          {buildDates(allAlbums.slice(-1)[0].played_on, allAlbums[0].played_on).map(year => (
            <li key={year} style={{ display:"inline", margin:8}}>
              <a href={`#${year}`} onClick={() => selectYear(year)}>{year}</a>
            </li>
          ))}
        </ol>}

        <hr />

        <p>General</p>
        <ul>
          <li>{Object.keys(theHosts).length} hosts</li>
          <li>{_.sortBy(theHosts, []).slice(-1)} is the most anyone has hosted</li>
        </ul>

        {currentYear
          ? <p>Hosted in {currentYear ? currentYear : "every year"}</p>
          : <p>Hosted by {currentHost ? currentHost : "everyone"}</p>
        }
        <ul>
          <li><del>{theAlbums.length} albums</del></li>
          <li><del>{_.uniqBy(theAlbums, "artist").length} different artists</del></li>
          <li><del>
            Host from {new Date(theAlbums.slice(-1)[0].played_on).toDateString()}
            {" "}to {new Date(theAlbums[0].played_on).toDateString()}
          </del></li>
          <li><del>
            Spanned {toDuration(
              new Date(theAlbums.slice(-1)[0].played_on),
              new Date(theAlbums[0].played_on)
            )}
          </del></li>
          <li><ins>Longest/shortest gap between albums</ins></li>
          <li><ins>Most popular day of the week</ins></li>
        </ul>

        <p>From <em>{_.filter(theAlbums, "year").length}</em> albums with <strong>year</strong> data…</p>
        <p><del>Most popular decade</del></p>
        {theYears && <table>
          <tbody><tr>
          {buildDecades(theYears.oldest, theYears.newest).map(year => (
            <td className={styles.date} key={year}>
              {theYears.decades[year] && parseInt(theYears.decades[year], 10)}
              {theYears.decades[year] ? <>
                <div className={styles.dateCount} style={{height: `${parseInt(theYears.decades[year], 10)*10}px`}} />
              </> : null}
              {year.toString(10).split("").slice(2)}
            </td>
          ))}
          </tr></tbody>
        </table>}
        <p>Most popular years</p>
        {theYears && <table>
          <tbody><tr>
          {buildDates(theYears.oldest, theYears.newest).map(year => (
            <td className={styles.date} key={year}>
              {theYears.all[year] && parseInt(theYears.all[year], 10)}
              {theYears.all[year] ? <>
                <div className={styles.dateCount} style={{height: `${parseInt(theYears.all[year], 10)*10}px`}} />
              </> : null}
              {year.toString(10).split("").slice(2)}
            </td>
          ))}
          </tr></tbody>
        </table>}
        <ul>
          {/* <li>Albums from {years(theAlbums).oldest} to {years(theAlbums).newest}</li> */}
          <li><del>Newest album</del></li>
          <li><del>Oldest album</del></li>
          <li><del>Freshest album (played since released)</del></li>
          <li><del>Most stale album (played since released)</del></li>
          <li><ins>Range of albums by year (years between oldest to newest)</ins></li>
        </ul>
        {!!theTracks && <>
        <p>From <em>{theTracks.byCount.length}</em> albums with <strong>track</strong> data…</p>
        <ul>
          <li><del>{theTracks.totalCount} tracks played</del></li>
          <li>{theTracks.totalLength} seconds listened</li>
          <li><del>{secondsToDuration(theTracks.totalLength)} listened</del></li>
          <li><del>"{theTracks.byLength[0]?.title}" is the shortest album</del></li>
          <li><del>"{theTracks.byLength.slice(-1)[0]?.title}" is the longest album</del></li>
          <li><ins>Longest/shortest average track length</ins></li>
        </ul>

        <p><del>Eponymously titled albums</del></p>
        <ul>
        {theAlbums
          .reduce((albums, album) => (
            album.title === album.artist
              ? [...albums, album]
              : albums
          ), [])
          .map(album => (
            <li key={album.number}>{album.title}</li>
          )
        )}
        </ul>

        <p>Artist</p>
        <ul>
          <li>
            <del>Artists played more than once</del>
            <ul>
            {Object.entries(byArtist(theAlbums))
              .reduce((artists, [artist, albums]) => (
                albums.length > 1
                  ? [...artists, [artist, albums]]
                  : artists
              ), [])
              .reverse()
              .map(([artist, albums]) => (
                <li key={artist}>
                  {artist}
                  <ul>
                    {albums.map(album => (
                      <li key={album}>{album}</li>
                    ))}
                  </ul>
                </li>
              ))
            }
            </ul>
          </li>
        </ul>

        <details>
          <summary>Debug</summary>
          <pre style={{fontFamily:'monospace'}}>{JSON.stringify(byArtist(theAlbums), null, 2)}</pre>
        </details>
        </>}
      </>)}
    </div>
  );
};

export default Stats;
